import React, { useState } from 'react';
import './Avatar.css';


const Avatar: React.FC = () => {
  const defaultAvatar = `${process.env.PUBLIC_URL}/images/avatar.png`;
  const [avatar, setAvatar] = useState<string>(defaultAvatar);

  const handleAvatarUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAvatar(e.target?.result as string);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div className="avatar-container">
      <img src={avatar} className="avatar-img" />
      <input type="file" onChange={handleAvatarUpload} className="avatar-input" />
    </div>
  );
};

export default Avatar;
