import React, { useState } from 'react';
import './EditableInfo.css';

interface EditableInfoProps {
  label: string;
  value: string;
  onSave: (newValue: string) => void;
}

const EditableInfo: React.FC<EditableInfoProps> = ({ label, value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(value);

  const handleSave = () => {
    onSave(newValue);
    setIsEditing(false);
  };

  return (
    <div className="editable-info">
      <label className="editable-label">{label}:</label>
      {isEditing ? (
        <input type="text" value={newValue} onChange={(e) => setNewValue(e.target.value)} className="editable-input" />
      ) : (
        <span className="editable-text">{value}</span>
      )}
      <button onClick={() => setIsEditing(!isEditing)} className="editable-button">
        {isEditing ? 'Save' : 'Edit'}
      </button>
    </div>
  );
};

export default EditableInfo;
