import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Avatar from './components/Avatar';
import EditableInfo from './components/EditableInfo';
import './HomePage.css';

const HomePage: React.FC = () => {
  const [name, setName] = useState('杏儿');
  const [age, setAge] = useState('18');
  const [honor, setHonor] = useState('曾荣获自己和自己玩大赛未获奖提名');

  return (
    <div className="homepage-container">
      <h1 className="title">我是您的 数字人助手 杏儿</h1>
      <div className="top-right">
        <Link to="/edit" className="edit-button">Edit Info</Link>
      </div>
      <div className="profile-section">
        <Avatar />
        <div className="info-section">
          <EditableInfo label="Name" value={name} onSave={setName} />
          <EditableInfo label="Age" value={age} onSave={setAge} />
          <EditableInfo label="Honor" value={honor} onSave={setHonor} />
        </div>
      </div>
    </div>
  );
};

export default HomePage;


