import React, { useState } from 'react';
import './EditInfoPage.css';

const EditInfoPage: React.FC = () => {
  const [name, setName] = useState('John Doe');
  const [age, setAge] = useState('30');
  const [email, setEmail] = useState('john.doe@example.com');
  const [message, setMessage] = useState<string | null>(null);

  const handleSave = async () => {
    try {
      const response = await fetch('http://localhost:3000/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, age, email }),
      });

      if (response.ok) {
        setMessage('保存成功');
      } else {
        console.log('22222')
        setMessage('保存失败');
      }
    } catch (error) {
      setMessage('保存失败');
    }

    setTimeout(() => {
      setMessage(null);
    }, 2000);
  };

  return (
    <div className="edit-info-container">
      <h1>Edit Your Information</h1>
      <form>
        <label>
          Name:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <label>
          Age:
          <input type="number" value={age} onChange={(e) => setAge(e.target.value)} />
        </label>
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        <button type="button" onClick={handleSave}>Save</button>
      </form>
      {message && <div className="message">{message}</div>}
    </div>
  );
};

export default EditInfoPage;
